import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ModelCompleteProfile from "./ModelCompleteProfile";
import ModelBankDetrails from "./ModelBankDetrails";
import ModelCryptoPayout from "./ModelCryptoPayout";
import { getModelDetail } from "../../services/services"


const buttonWidth = {
  width: "100%",
};

const ViewModelDetails = (props) => {
  const { show, handleClose, modelId } = props;
  const [key, setKey] = useState('home');
  const [modelProfile, setModelProfile] = useState('')
  const [bankDetail, setBankDetail] = useState('')
  const [cryptoPayout, setCryptoPayout] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
    handleClose()
  };
  const modelDetail = () => {
    getModelDetail(modelId).then(res => {
      if (res?.status == 200) {
        console.log('res====>',res)
        setModelProfile(res?.data?.data?.model)
        setBankDetail(res?.data?.data?.bankDetail)
        setCryptoPayout(res?.data?.data?.cryptoPayout)
      }
    }).catch((err) => {
      if (err.response.status == 400 || err.response.status == 401) {
        toast.error(err.response.data.message)
      }
    })
  }

  useEffect(() => {
    modelDetail()
  }, [modelId])

  return (
    <Modal show={show} onHide={handleClose} centered size="lg" scrollable>
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">Model Details</Modal.Title>
        </div>
      </Modal.Header>

      <Modal.Body className="p-4">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="home" title="Model Profile" className="">
            <ModelCompleteProfile modelProfile={modelProfile} />
          </Tab>
          <Tab eventKey="profile" title="Bank Details">
            <ModelBankDetrails bankDetail={bankDetail} />
          </Tab>
          <Tab eventKey="contact" title="Crypto Payout">
            <ModelCryptoPayout cryptoPayout={cryptoPayout} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};



export default ViewModelDetails