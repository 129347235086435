import {Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { retrieveBankDetail } from "../../services/services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Logo from "../../assets/images/logo.png"


const ViewBanksDetails = () => {
   let params = useParams()
   const navigate = useNavigate()
   const [user,setUser]= useState({})
   const RetrieveProfile = () => {
    retrieveBankDetail(params.id).then(res=>{
         if(res.status==200){
            setUser(res.data.data)
         }
      }).catch((err)=>{
         if (err.response.status == 401) {
            toast.error(err.response.data.message)
            //  localStorage .clear()
            // navigate('/')
        }if (err.response.status == 400) {
         toast.error(err.response.data.message)
         //  localStorage .clear()
         // navigate('/')
     }
         // toast.error(err.response.data.message)
      })
   }

   useEffect(() => {
      RetrieveProfile()
   }, []);
   return (
      <>
         <main className="content">
            <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
               <i className="ri-menu-line ri-xl"></i>
            </a>

            <div className="container-fluid">
               <div className="row mt-3 mb-4">
                  <div className="col-md-3">
                     <Link to={"/bank-details"} className="text-dark d-flex fw-bolder"><i className="ri-arrow-left-line"></i>Back</Link>
                  </div>
               </div>
            </div>
            <div className="container-fluid">
               <div className="row">
                  <div className="col-12">
                     <div className="card border-0 manage-usr-text mb-5">

                        <div className="card-header bg-white border-0">
                           <h4 className="text-contact mt-2">View</h4>
                        </div>
                        <div className="card-body">
                           <div className="row align-items-center">
                              <div className="col-md-3">
                                 <figure>
                                    <img src={Logo} alt="image" width="200" height="200" className="rounded-circle mx-auto d-block" />
                                    <figcaption className="text-center mt-3 text-dark fw-bolder">Profile Picture</figcaption>
                                 </figure>

                              </div>
                              <div className="col-md-9">

                                 <div className="row">

                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">First Name</p>
                                          <h5>{user.firstName}</h5>
                                       </div>
                                    </div>


                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Last Name</p>
                                          <h5>{user.lastName}</h5>
                                       </div>
                                    </div>

                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Email</p>
                                          <h5>{user.email}</h5>
                                       </div>
                                    </div>


                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Phone Number</p>
                                          <h5>{user.phone}</h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Account Name</p>
                                          <h5>{user.accountName}</h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Account Number</p>
                                          <h5>{user.accountNumber}</h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Account Type</p>
                                          <h5>{user.accountType}</h5>
                                       </div>
                                    </div>

                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Bank Name</p>
                                          <h5>{user.bankName} </h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Bank Code</p>
                                          <h5>{user.bankCode}</h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Currency</p>
                                          <h5>{user.currency} </h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Bank Location</p>
                                          <h5>{user.bankLocation} </h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">City</p>
                                          <h5>{user.city} </h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Address Line1</p>
                                          <h5>{user.addressLine1} </h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Address Line2</p>
                                          <h5>{user.addressLine2} </h5>
                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Swift Code</p>
                                          <h5>{user.swiftCode} </h5>
                                       </div>
                                    </div>
                                    <div className="col-md-12">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Bank Country</p>
                                          <h5>{user.bankCountry}</h5>
                                       </div>
                                    </div>
                                    


                                    <div className="col-md-2">
                                      <button type="button" className="btn btn-linear btn-block w-100" onClick={()=>navigate(-1)}>Close</button>
                                    </div>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      </>
   );
}


export default ViewBanksDetails