
import { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
// import { BiEnvelope, BiLock } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs"
import { addCountryRegion, deleteCountryOrRegion, getCountriesAndRegion } from "../services/services";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { RiDeleteBin3Fill } from "react-icons/ri";
import Swal from "sweetalert2";

const CountryRegion = () => {
    const [show, setShow] = useState(false);
    const [countriesRegions, setCountriesRegions] = useState([]);
    let navigate = useNavigate()
    const [key, setKey] = useState('countries');

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm()
    const handleClose = () => {
        setShow(false);
        reset()
    };
    const handleShow = () => setShow(true);

    const submit = (data) => {
        addCountryRegion(data).then(res => {
            if (res.status == 201) {
                GetCountriesAndRegions()
                toast.success(res.data.message)
                reset()
                handleClose()
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })
    };

    const GetCountriesAndRegions = () => {
        getCountriesAndRegion().then(res => {
            if (res.status == 200) {

                setCountriesRegions(res.data.data)

            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })
    }

    const handleDeletion = (id) => {
        Swal.fire({
            title: `Do you want to delete this entry?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            confirmButtonColor: "#FF4D67",
            // cancelButtonColor:"#e7e7e7"
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                deleteCountryOrRegion(id).then(res => {
                    if (res.status == 200) {
                        GetCountriesAndRegions()
                        toast.success("successfully deleted.")
                    }
                }).catch((err) => {
                    if (err.response.status == 401) {
                        toast.error(err.response.data.message)
                        // localStorage.clear()
                        // navigate('/')
                    }
                    if (err.response.status == 400) {
                        toast.error(err.response.data.message)
                        // localStorage.clear()
                        // navigate('/')
                    }
                })
            } else if (result.isDenied) {
                Swal.close()
            }
        })
    }

    useEffect(() => {
        GetCountriesAndRegions()
    }, []);


    return (
        <>
            <main className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix mt-3 mb-4">
                                <div className="float-start">
                                    <h2 className="text-contact">Countrie & Regions</h2>
                                </div>
                                {/* <p>The count is {count}</p> */}
                                {/* <div className="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb contact-bread">
                                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                                <li className="breadcrumb-item"><a href="#">Manage Static</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Faq</li>
                                            </ol>
                                        </nav>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <span className="see-more" onClick={handleShow} >Add New <BsPlusLg style={{ marginLeft: "5px" }} /></span>
                        <Tabs
                            defaultActiveKey="countries"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="countries" title="Countries">
                                <div className="table-responsive">
                                    <table className="table table-first text-nowrap" id="table">
                                        <thead>
                                            <tr>
                                                <th>Sr no.</th>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {countriesRegions.length!==0 && countriesRegions.filter((entry) => entry.type == "country").map((entry, i) =>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{entry.name}</td>

                                                    <td>
                                                        <Link onClick={() => handleDeletion(entry._id)}>
                                                            <button className="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                            </button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                            </Tab>
                            <Tab eventKey="regions" title="Regions">
                                <div className="table-responsive">
                                    <table className="table table-first text-nowrap" id="table">
                                        <thead>
                                            <tr>
                                                <th>Sr no.</th>
                                                <th>Country</th>
                                                <th>Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {countriesRegions.length!==0 && countriesRegions.filter((entry) => entry.type == "region").map((entry, i) =>
                                                <tr>
                                                    <td>{i + 1}</td>
                                                    <td>{entry.country.name}</td>
                                                    <td>{entry.name}</td>

                                                    <td>
                                                        <Link onClick={() => handleDeletion(entry._id)}>
                                                            <button className="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                            </button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                </div>
                            </Tab>

                        </Tabs>
                    </div>
                    <Modal show={show} onHide={handleClose} centered>

                        <Modal.Body>
                            <form className="form-design" onSubmit={handleSubmit(submit)}>
                                <div className="mb-4">
                                    <label>Select what you want to add.</label>
                                    <div className="input-group">
                                        <select className="form-select" {...register('type')}>
                                            <option value="country">Country</option>
                                            <option value="region">Region</option>

                                        </select>

                                    </div>
                                    {errors.type && <span className='error'>{errors.type.message}</span>}
                                </div>
                                <div className="mb-4">
                                    <label>Name</label>
                                    <div className="input-group">

                                        <input type="text" className="form-control" placeholder="Enter Name" {...register('name', { required: "Name  is required." })} />
                                    </div>
                                    {errors.name && <span className='error'>{errors.name.message}</span>}
                                </div>
                                {watch('type') == "region" &&
                                    <div className="mb-4">
                                        <label>Select Country</label>
                                        <div className="input-group">
                                            <select className="form-select" {...register('country', { required: "Please choose country." })}>
                                                {countriesRegions && countriesRegions.filter((entry) => entry.type == "country").map((country) =>
                                                    <option value={country._id}>{country.name}</option>

                                                )}
                                            </select>
                                        </div>
                                        {errors.type && <span className='error'>{errors.type.message}</span>}
                                    </div>
                                }
                                <button type="submit" className="btn btn-linear btn-block w-100">Add Entry</button>
                            </form>
                        </Modal.Body>

                    </Modal>
                </div>
            </main>
        </>
    );
}

export default CountryRegion;