import React from 'react'
import { GetUrl } from '../../config/GetUrl'
import WrittingPng from '../../assets/images/writings.png'
import Logo from "../../assets/images/no_image.png"

const ModelCompleteProfile = (props) => {
    const { modelProfile } = props
console.log("modelProfile===>",modelProfile)
    return (
        <div><form className="form-login n  n">

            <div className="mb-4">
                <label className="form-label">Image</label>
                <div>
                    <img src={modelProfile?.avatar?modelProfile.avatar:Logo} alt="" className="new object-fit-cover" width={100} height={100} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4"><div className="mb-4">
                    <label className="form-label">Name</label>
                    <p className="h5">{modelProfile?.username ? modelProfile?.username : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"><div className="mb-4">
                    <label className="form-label">Email</label>
                    <p className="h6">{modelProfile?.email ? modelProfile?.email : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4">
                    <label className="form-label">Language</label>
                    <p className="h6">{modelProfile?.language ? modelProfile?.language : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                    <label className="form-label">Region</label>
                    <p className="h6">{modelProfile?.region ? modelProfile?.region : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                    <label className="form-label">Age</label>
                    <p className="h6">{modelProfile?.age ? modelProfile?.age : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Mobile Number</label>
                    <p className="h6">{modelProfile?.mobile_number ? modelProfile?.mobile_number : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                    <label className="form-label">Address</label>
                    <p className="h6">{modelProfile?.address ? modelProfile?.address : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Postal Code</label>
                    <p className="h6">{modelProfile?.postal_code ? modelProfile?.postal_code : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Invitation Code</label>
                    <p className="h6">{modelProfile?.invitation_code ? modelProfile?.invitation_code : 'NIL'}</p>

                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Gender</label>
                    <p className="h6">{modelProfile?.gender ? modelProfile?.gender : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Preffered Gender</label>
                    <p className="h6">{modelProfile?.preffered_gender ? modelProfile?.preffered_gender : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Status</label>
                    <p className="h6">{modelProfile?.status ? modelProfile?.status : 'NIL'}</p>
                </div></div>
                <div className="col-md-4"><div className="mb-4" >
                <label className="form-label">Document (Selfi Verification)</label>
                {modelProfile?.selfiVerification?<div class="card">
                        <div class="card-body">
                            <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                        </div>

                        <a href={GetUrl.IMAGE_URL + modelProfile?.selfiVerification} target="_blank" class="stretched-link"></a>
                    </div>:<p className="h5">NIL</p>} 
                </div></div>
                <div className="col-md-4"> <div className="mb-4" >
                    <label className="form-label">Independent Contract Agreement</label>
                    <p className="h6">{modelProfile?.flirtProfile ? 'Yes' : 'No'}</p>
                </div></div>
                
            </div>
        </form></div>
    ) 
}

export default ModelCompleteProfile