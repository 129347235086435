import React, { useEffect, useState } from 'react'
import { RiEditFill, RiEyeFill } from 'react-icons/ri'
import { MdDelete } from "react-icons/md";
import { getReport,deleteReport } from "../../services/services"
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate"
import DeleteReport from "./deleteReport/ReportDelete"
import ViewReport from './viewReport/ViewReport';
import LoadingAction from '../Loader';

const Report = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [viewShow, setViewShow] = useState(false)
    const [reportList, setReportList] = useState([])
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')
    const [reportId, setReportId] = useState('')
    const [showLoader, setShowLoader] = useState(false);

    const getReportList = () => {
        getReport().then(res => {
            if (res?.status == 200) {
                console.log('res.data.data-->',res.data.data)
                setReportList(res.data.data)
            }
        }).catch((err) => {
            if (err.response?.status == 401 || err.response?.status == 400) {
                toast.error(err.response.data.message)
            }
        })
    }
    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search?.length > 0) {
            let searchArr = reportList.filter((item) => item?.reported?.username?.toLowerCase().includes(search.toLowerCase()) || item?.reportedBy?.username?.toLowerCase().includes(search.toLowerCase()))
            setPageCount(Math.ceil(searchArr?.length / itemsPerPage))
            paginated = searchArr.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(reportList?.length / itemsPerPage))
            paginated = reportList.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }
    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }

    const handlePageClick = (e) => {
        setPage(e.selected)
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }
    const handleDeletion = (id) => {
        setReportId(id)
        setShowDeleteModal(true)
    }
    const viewHandler=(id)=>{
        setReportId(id)
        setViewShow(true)
    }
    const handleClose = () => {
        setShowDeleteModal(false)   
        setViewShow(false)
    }
    const handleDelete = ()=>{
        setShowLoader(true)
        deleteReport(reportId).then(res => {
            if (res.status == 200) {
                setShowLoader(false)
                toast.success(res?.data?.message)
                getReportList()
               
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                setShowLoader(false)
                toast.error(err.response.data.message)
            }
            if (err.response.status == 400) {
                setShowLoader(false)
                toast.error(err.response.data.message)
            }
        })
    }
    useEffect(() => {
        getReportList()
    }, [])
    useEffect(() => {
        paginationHandler()
    }, [reportList, offset, search])

    return (
        <>
            <main className="content">
                <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                    <i className="ri-menu-line ri-xl"></i>
                </a>
                {showLoader && <LoadingAction />} 
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix mt-3 mb-4">
                                <div className="float-start">
                                    <h2 className="text-contact">Reports</h2>
                                </div>
                                <div className="float-end">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb contact-bread">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Reports</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    {viewShow && (<ViewReport viewShow={viewShow} handleClose={handleClose} reportId={reportId} />)}
                { showDeleteModal && (<DeleteReport showDeleteModal={showDeleteModal}  handleClose={handleClose} handleDelete={handleDelete}/>)}
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0 manage-usr-text mb-5">
                                <div className="card-body">
                                    <div className="search-user">
                                        <label>Search:<input type="search" className="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /></label>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-first text-nowrap" id="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr no.</th>
                                                    <th>Reported</th>
                                                    <th>Reported By</th>
                                                    <th>Reason</th>
                                                    <th>Date Created</th>
                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginated && paginated.map((report, i) =>
                                                    <tr key={i}>                                               
                                                        <td>{((page + 1) * itemsPerPage - itemsPerPage) + i + 1}</td>
                                                        <td>{report?.reported?.username?.length > 10 ? report?.reported?.username.substring(0, 12) + '...' : report?.reported?.username}</td>
                                                        <td>{report?.reportedBy?.username?.length > 10 ? report?.reportedBy?.username.substring(0, 12) + '...' : report?.reportedBy?.username}</td>
                                                        <td>{report?.reason?.length > 12 ? report?.reason.substring(0, 15) + '...' : report?.reason}</td>
                                                        <td>{report?.createdAt.split('T')[0]}</td>
                                                        <td align="center">
                                                            <button className="btn btn-light me-2" type="button" onClick={() => handleDeletion(report._id)} ><MdDelete /></button>
                                                            <button className="btn btn-light " type="button" onClick={() => viewHandler(report._id)} ><RiEyeFill /></button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {reportList?.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {reportList?.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>

        </>
    );
}


export default Report