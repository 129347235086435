import { useForm } from 'react-hook-form';
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { adminLogin } from '../services/services';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BiEnvelope, BiLock } from 'react-icons/bi'
import useAdminContext from '../context/appContext';
import { BiSolidHide,BiShow } from "react-icons/bi";

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm()
    const navigate = useNavigate()
    const { setIsLoggedIn, setToken, setAdminAccess } = useAdminContext()
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const submit = (data) => {
        adminLogin(data).then(res => {
            if (res?.status == 200) {
                if (res?.data.data.role == 'admin') {
                    navigate('/dashboard')
                } else {
                    let expression = res?.data?.data.modules[0].moduleName
                    switch (expression) {
                        case "Manage User":
                            navigate('/users')
                            break;
                        case "Manage Model":
                            navigate('/models')
                            break;
                        case "Manage Transaction":
                            navigate('/transactions')
                            break;
                        case "Manage User Support":
                            navigate('/manage-usersupport')
                            break;
                        case "Manage Static":
                            navigate('/region-country')
                            break;
                        case "Model Withdrawals":
                            navigate('/model-withdrawls')
                            break;
                        case "Bank Details":
                            navigate('/bank-details')
                            break;
                        case "Crypto Payout":
                            navigate('/crypto-payout')
                            break;
                        case "Invitation Code":
                            navigate('/invitation-code')
                            break;                     
                        default:
                            console.log("No Module")
                            break;
                    }
                }

                localStorage.setItem('token', res?.data?.token)
                localStorage.setItem('userId', res?.data?.data._id)
                localStorage.setItem('adminAccess', JSON.stringify(res?.data?.data.modules))
                // localStorage.setItem('session',res.data.data.session)
                axios.defaults.headers.common['Authorization'] = `Bearer ${res?.data?.token}`;
                setIsLoggedIn(true)
                setToken(res?.data?.token)
                setAdminAccess(JSON.stringify(res?.data?.data.modules))
                toast.success("logged in successfully.")

            }
        }).catch(err => {
            if (err?.response?.status == 400) {
                toast.error(err.response.data.message)
            }
        }).catch(err => {
            if (err?.response?.status == 400) {
                toast.error(err.response.data.message)
            }
        })
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center mobile-reverse">

                    <div className="col-xl-5 col-lg-5 ">
                        <div className="card border-0 login-form mt-lg-5 mt-2 mx-xl-5 mx-lg-3">
                            <div className="card-body p-xl-5">
                                <div className="logo mb-4">
                                    <a href="login.html">
                                        <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" width="150" />
                                    </a>
                                </div>
                                <h4 className="fw-bolder mb-2">Login to your account</h4>
                                <p className="mb-4 small">Enter your email address and password to access panel.</p>
                                <form className="form-design" onSubmit={handleSubmit(submit)}>
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                {/* <i className="fa fa-envelope"></i> */}
                                                <BiEnvelope style={{ fontSize: "20px" }} />
                                            </span>
                                            <input type="text" className="form-control" placeholder="Enter Email Address" {...register('email', { required: "Email is required.", pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Invalid email" } })} />
                                        </div>
                                        {errors.email && <span className='error'>{errors.email.message}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <div className="input-group position-relative">
                                            <span className="input-group-text" id="basic-addon1">
                                                {/* <i className="fa fa-lock"></i> */}
                                                <BiLock style={{ fontSize: "20px" }} />
                                            </span>
                                            <input type={showPassword ? "text" : "password"} className="form-control" placeholder="Enter Password" {...register('password', { required: "Password is required." })} />
                                            <div className='password-hide-show' onClick={togglePasswordVisibility}>{showPassword?<BiShow />:<BiSolidHide />} </div>
                                           
                                        </div>
                                        {errors.password && <span className='error'>{errors.password.message}</span>}
                                    </div>
                                    <div className="clearfix mb-5">
                                        <div className="float-start">
                                            {/* <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                                    <label className="form-check-label" for="exampleCheck1">Remember me</label>
                                            </div> */}
                                        </div>
                                        <div className="float-end">
                                            <div>
                                                <Link to="/forget-password" className="text-decoration-none mb-4 small forgot-password">Forgot Password ?</Link>
                                            </div>

                                        </div>
                                    </div>
                                    <button type="submit" className="btn btn-linear btn-block w-100">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* <!-- Shapes --> */}
                    <div className="shape"></div>
                    <div className="shape-2"></div>
                    {/* <!-- End Shapes --> */}

                    <div className="col-xl-7 px-0 col-lg-7">
                        <div className="bg-after h-100">
                            <img src={require('../assets/images/login.jpg')} alt="" className="img-fluid bg-login w-100" />
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Login;