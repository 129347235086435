import React, { useState, useEffect } from "react";
import { getTermsCondition } from "../../services/services";

const TermsCondition = () => {
  const [data, setData] = useState("");

  const getContent = () => {
    getTermsCondition().then((res) => {
      if (res?.status === 200) {
        if (res?.data?.data?.length !== 0) {
          setData(res?.data?.data?.content);
        }
      }
    });
  };

  useEffect(() => {
    getContent();
  }, []);

  const renderHTML = () => {
    return { __html: data };
  };

  return (
    <div className="m-4">
      <h2 className="text-contact mb-4">Terms & conditions</h2>
      <div
        className="static-content-web-url"
        dangerouslySetInnerHTML={renderHTML()}
      />
      {/* <div>{JSON.stringify(data)}</div> */}
    </div>
  );
};

export default TermsCondition;
