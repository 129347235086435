import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { getSubAdminDetail } from "../../../services/services";
import { ShimmerText } from "react-shimmer-effects";


const buttonWidth = {
    width: "100%",
};

const ViewSubAdmin = (props) => {
    const { viewShow, handleClose,subAdminId } = props;
    const [subAdmin, setSubAdmin] = useState('');
    const [loading, setLoading] = useState(true);
    
    const getsubAdminData = () => {
        getSubAdminDetail(subAdminId).then((res) => {
            if (res.status === 200) {
                setSubAdmin(res.data.data);
                setLoading(false); 
            }
        });
    };

    useEffect(() => {
        getsubAdminData();
    }, [subAdminId]);
   

    const handleSubmit = (e) => {
        e.preventDefault();
         handleClose()

    };

    return (
        <Modal show={viewShow} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">View Sub Admin</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n  n">

                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        {loading ? (
                            <ShimmerText line={1} />
                        ) : (
                            <p className="h5">{subAdmin.username}</p>
                        )}

                    </div>
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        {loading ? (
                            <ShimmerText line={1}  />
                        ) : (
                            <p className="h5">{subAdmin.email}</p>
                        )}
                       
                    </div>
                    {/* <div className="mb-4">
                        <label className="form-label">Password</label>
                        <p className="h5">{subAdmin.username}</p>

                    </div> */}
                    <div className="mb-4" >
                        <div>
                            <label className="form-label">Modules</label>
                        </div>
                        <div className="grid-module">
                        {loading ? (
                                <ShimmerText line={1}  />
                            ) : (
                                subAdmin?.modules?.map((item, i) => (
                                    <div className="grid-module" key={i}>
                                        <label className="h5">{item.moduleName}</label>
                                    </div>
                                ))
                            )}
                            
                        </div>

                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-invite next fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};




export default ViewSubAdmin