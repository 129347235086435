import React, { useEffect, useState } from 'react'
import { RiEditFill } from 'react-icons/ri'
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom'
import AddInvitationCode from './addInvitation/AddInvitationCode'
import EditInvitationCode from './editInvitationCode/EditInvitationCode';
import DeleteInvitationCode from './deleteInvitationCode/DeleteInvitationCode';
import { getInvitationCode,deleteInvitationCode } from "../../services/services"
import { toast } from "react-toastify";

const InvitationCode = () => {
    const [show, setShow] = useState(false)
    const [editShow, setEditShow] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [invitationCodeList, setInvitationCodeList] = useState([])
    const [invitationCodeId, setInvitationCodeId] = useState('')

    const InvitationList = () => {
        getInvitationCode().then(res => {
            if (res.status == 200) {
                setInvitationCodeList(res?.data?.data)
            }
        }).catch((err) => {
            console.log(err.response.status);
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
            }
        })
    }

    const handleClose = () => {
        setShow(false)
        setEditShow(false)
        setShowDeleteModal(false)
    }
const editHandler = (id) =>{
    setInvitationCodeId(id)
    setEditShow(true)
}

const deleteHandler = (id) =>{
    setInvitationCodeId(id)
    setShowDeleteModal(true)
}
const onDataEditPass = (newData) =>{
    if(newData){
        InvitationList()
    }
}
const onDataPassCreateCode =(newData)=>{
    if(newData){
        InvitationList()
    }
}
const handleDeleteCategory = () => {
    deleteInvitationCode(invitationCodeId).then(res => {
        if (res.status == 200) { 
            toast.success(res?.data?.message)
            InvitationList()
        }
    }).catch((err) => {
        if (err.response.status == 401) {
            toast.error(err.response.data.message)
        }
        if (err.response.status == 400) {
            toast.error(err.response.data.message)
        }
    })
  };

    useEffect(() => {
        InvitationList()
    }, [])
    return (
        <>
            <main className="content">
                <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                    <i className="ri-menu-line ri-xl"></i>
                </a>
                <AddInvitationCode show={show} handleClose={handleClose} onDataPassCreateCode={onDataPassCreateCode}/>
               {editShow && (<EditInvitationCode editShow={editShow} handleClose={handleClose} invitationCodeId={invitationCodeId} onDataEditPass={onDataEditPass}/>)} 
                {showDeleteModal && (<DeleteInvitationCode showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteCategory}/>)}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix mt-3 mb-4">
                                <div className="float-start">
                                    <h2 className="text-contact">Manage Invitation Code</h2>
                                </div>
                                <div className="float-end">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb contact-bread">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Invitation Code</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0 manage-usr-text mb-5">
                                <div className="card-body">
                                    <div className="search-user">
                                        {/* <label>Search:<input type="search" className="search-input" placeholder="" aria-controls="table"  /></label> */}
                                        <button className='btn btn-invite' onClick={() => setShow(true)}> + Add Invitation Code</button>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-first text-nowrap" id="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr no.</th>
                                                    <th>Name</th>
                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invitationCodeList && invitationCodeList.map((code, i) =>
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{code?.invitationCode}</td>
                                                        <td align="center">
                                                            <button className="btn btn-light me-2" type="button" onClick={()=>editHandler(code?._id)}><RiEditFill /></button>
                                                            <button className="btn btn-light " type="button" onClick={()=>deleteHandler(code?._id)}><MdDelete /></button>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                        {invitationCodeList.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        </>
    );
}



export default InvitationCode