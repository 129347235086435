import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { getReportDetail } from "../../../services/services";
import { ShimmerText } from "react-shimmer-effects";


const buttonWidth = {
    width: "100%",
};

const ViewReport = (props) => {
    const { viewShow, handleClose,reportId } = props;
    const [reportDetail, setReportDetail] = useState('');
    const [loading, setLoading] = useState(true);
    
    const getsubAdminData = () => {
        getReportDetail(reportId).then((res) => {
            console.log('res--view---->',res)
            if (res.status === 200) {
                setReportDetail(res.data.data);
                setLoading(false); 
            }
        });
    };

    useEffect(() => {
        getsubAdminData();
    }, [reportId]);
   

    const handleSubmit = (e) => {
        e.preventDefault();
         handleClose()

    };

    return (
        <Modal show={viewShow} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">View Report</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n  n">

                    <div className="mb-4">
                        <label className="form-label">Model Name</label>
                        {loading ? (
                            <ShimmerText line={1} />
                        ) : (
                            <p className="h5">{reportDetail.reported.username}</p>
                        )}
                    </div>
                    <div className="mb-4">
                        <label className="form-label">User Name</label>
                        {loading ? (
                            <ShimmerText line={1}  />
                        ) : (
                            <p className="h5">{reportDetail.reportedBy.username}</p>
                        )}
                       
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Reason</label>
                        {loading ? (
                            <ShimmerText line={1}  />
                        ) : (
                            <p className="h5">{reportDetail.reason}</p>
                        )}
                       
                    </div>
                    <div className="mb-4">
                        <label className="form-label">Date Created</label>
                        {loading ? (
                            <ShimmerText line={1}  />
                        ) : (
                            <p className="h5">{reportDetail.createdAt.split('T')[0]}</p>
                        )}
                       
                    </div>
                    
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-invite next fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Close
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ViewReport