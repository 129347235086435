import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getSubAdminDetail, editSubAdmin } from "../../../services/services";
import { toast } from "react-toastify";
import LoadingAction from "../../Loader";

const buttonWidth = {
    width: "100%",
};

const EditSubAdmin = (props) => {
    const { editShow, handleClose, modulesList, subAdminId, editSubAdminPass } = props;
    const [subAdmin, setSubAdmin] = useState({
        username: "",
        email: "",
        modules: [], // Contains module objects
    });
    const [showLoader, setShowLoader] = useState(false);
    const [loadingInput, setLoadingInput] = useState(false);

    const getsubAdminData = () => {
        setLoadingInput(true); // Show shimmer effect
        getSubAdminDetail(subAdminId)
            .then((res) => {
                if (res.status === 200) {
                    setSubAdmin(res.data.data);
                }
                setLoadingInput(false); // Hide shimmer effect
            })
            .catch((error) => {
                console.error("Error fetching sub admin data:", error);
                setLoadingInput(false); // Hide shimmer effect on error
            });
    };

    useEffect(() => {
        getsubAdminData();
    }, [subAdminId]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            setSubAdmin((prevState) => {
                if (checked) {
                    // Add module
                    const moduleToAdd = modulesList.find((module) => module._id === value);
                    return {
                        ...prevState,
                        modules: [...prevState.modules, moduleToAdd],
                    };
                } else {
                    // Remove module
                    return {
                        ...prevState,
                        modules: prevState.modules.filter((module) => module._id !== value),
                    };
                }
            });
        } else {
            // Update text input (username or email)
            setSubAdmin((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowLoader(true)
        editSubAdmin(subAdmin, subAdminId).then(res => {
            if (res.status == 200) {
                toast.success(res.data.message)
                editSubAdminPass(res.data.success)
                setShowLoader(false)
                handleClose()
                handleClose()
                setSubAdmin({
                    username: "",
                    email: "",
                    modules: [],
                });
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                setShowLoader(false)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                setShowLoader(false)
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })

    };

    return (
        <Modal show={editShow} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">Edit Sub Admin</Modal.Title>
                </div>
            </Modal.Header>
            {showLoader && <LoadingAction />}

            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        {loadingInput ? (
                             <div className="shimmer-input mb-3 form-control"></div> // Use ShimmerInput for loading state
                        ) : (
                            <input
                                type="text"
                                className="form-control py-3"
                                name="username"
                                value={subAdmin.username}
                                onChange={handleChange}
                                required
                            />
                        )}

                    </div>
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        {loadingInput ? (
                             <div className="shimmer-input mb-3 form-control"></div> // Use ShimmerInput for loading state
                        ) : (
                            <input
                            type="email"
                            className="form-control py-3"
                            name="email"
                            value={subAdmin.email}
                            onChange={handleChange}
                            required
                        />
                        )}
                       
                        
                    </div>
                    <div className="mb-4">
                        <div>
                            <label className="form-label">Select Modules</label>
                        </div>
                        <div className="grid-module">
                            {modulesList.map((module) => (
                                <label key={module._id} className="mb-2">
                                    <input
                                        type="checkbox"
                                        name="modules"
                                        className="me-2"
                                        value={module._id}
                                        checked={subAdmin.modules.some(
                                            (item) => item._id === module._id
                                        )}
                                        onChange={handleChange}
                                    />
                                    {module.moduleName}
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-invite next fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default EditSubAdmin;
