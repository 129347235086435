import { useEffect, useRef, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { RiAppsFill, RiCustomerService2Fill, RiFileCopy2Fill, RiLogoutBoxRFill, RiTeamFill, RiWallet2Fill, RiBankFill, RiBitCoinFill } from 'react-icons/ri'
import { MdOutlineInsertInvitation } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { Link, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi'
import { adminLogout } from '../services/services';
import { toast } from 'react-toastify';
import useAdminContext from '../context/appContext';
import LogoutAccount from './LogoutAccount';

const Sidebar = () => {
    const { setIsLoggedIn, setToken, adminAccess, setAdminAccess } = useAdminContext()
    const [subAdminAccess, setSubAdminAccess] = useState([])
    const [showLogoutModal, setShowLogoutModal] = useState(false)
    const navigate = useNavigate()
    let modelMenu = useRef()
    let modelSubmenu = useRef()
    let staticMenu = useRef()
    let staticSubMenu = useRef()
    const handleModelMenu = () => {
        let ele = modelMenu.current
        let subele = modelSubmenu.current
        if (!ele?.classList.contains('open')) {
            ele.classList.add("open")
            subele.style.display = "block"
        } else {
            ele.classList.remove('open')
            subele.style.display = "none"
        }
    }

    const handleStaticMenu = () => {
        let ele = staticMenu.current
        let subele = staticSubMenu.current
        if (!ele?.classList.contains('open')) {
            ele.classList.add("open")
            subele.style.display = "block"
        } else {
            ele.classList.remove('open')
            subele.style.display = "none"
        }
    }

    const handleDelete = () => {
        adminLogout()
            .then(res => {
                if (res.status === 200) {
                    toast.success(res?.data?.message);
                    setIsLoggedIn(false)
                    localStorage.clear()
                    setToken("")
                    setAdminAccess([''])
                    navigate("/")
                }
            })
            .catch(err => {
                console.log(err, "errrrrrrrr");
                toast.error(err?.response?.data?.message);
                localStorage.clear()
            });

    }

    const getSubAdminData = () => {
        let sub = JSON.parse(localStorage.getItem("adminAccess"));
        setSubAdminAccess(sub)


    }

    const handleClose = () => {
        setShowLogoutModal(false)
    }

    useEffect(() => {
        getSubAdminData()
    }, [])

    return (
        <>
            <aside id="sidebar" class="sidebar break-point-sm has-bg-image">
                <div class="image-wrapper">
                </div>
                <div class="sidebar-layout">
                    <div class="sidebar-header">
                        <div class="pro-sidebar-logo">
                            <a href="dashboard.html">
                                <img src={require('../assets/images/logo.png')} alt="logo" class="img-fluid" width="100" />
                            </a>
                        </div>
                    </div>
                    {showLogoutModal && (<LogoutAccount showLogoutModal={showLogoutModal} handleClose={handleClose} handleDelete={handleDelete} />)}
                    <div class="sidebar-content">
                        <nav class="menu open-current-submenu">
                            <ul>
                                <li class="menu-header"><span> GENERAL </span></li>
                                {subAdminAccess?.some(item => item.moduleName === "Dashboard") &&
                                    <li class={(window.location.pathname == "/dashboard" || window.location.pathname == "/") ? "menu-item active" : "menu-item"}>
                                        <Link to="/dashboard">
                                            <span class="menu-icon">
                                                <RiAppsFill />
                                            </span>
                                            <span class="menu-title">Dashboard</span>
                                        </Link>
                                    </li>
                                }

                                {subAdminAccess?.some(item => item.moduleName === "Manage User") && <li className={window.location.pathname.startsWith('/user') ? "menu-item active" : "menu-item"}>
                                    <Link to="/users">
                                        <span class="menu-icon">
                                            <RiTeamFill />
                                        </span>
                                        <span class="menu-title">Manage Users</span>
                                    </Link>
                                </li>}

                                {subAdminAccess?.some(item => item.moduleName === "Manage Model") &&
                                    <li ref={modelMenu} className={(window.location.pathname.startsWith('/models') || window.location.pathname.startsWith('/unappoved-models') || window.location.pathname.startsWith('/rejected-models')) ? "menu-item sub-menu active" : "menu-item sub-menu"} onClick={handleModelMenu}>
                                        <Link >
                                            <span class="menu-icon" >
                                                <RiFileCopy2Fill />
                                            </span>
                                            <span class="menu-title">Manage Models</span>
                                        </Link>
                                        <div ref={modelSubmenu} className="sub-menu-list" onClick={(e) => e.stopPropagation()} >
                                            <ul>
                                                <li class="menu-item">
                                                    <Link to="/models">
                                                        <span className={window.location.pathname.startsWith('/models') ? "menu-title active" : "menu-title"}>Registered Models</span>
                                                    </Link>
                                                </li>
                                                <li class="menu-item">
                                                    <Link to="/unappoved-models">
                                                        <span className={window.location.pathname.startsWith('/unappoved-models') ? "menu-title active" : "menu-title"}>Requested Models</span>
                                                    </Link>
                                                </li>
                                                <li class="menu-item">
                                                    <Link to="/rejected-models">
                                                        <span className={window.location.pathname.startsWith('/rejected-models') ? "menu-title active" : "menu-title"}>Rejected Models</span>
                                                    </Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </li>
                                }

                                {/* {subAdminAccess.some(item => item.moduleName == "Manage Transaction") && <li className={window.location.pathname.startsWith('/transactions') ? "menu-item active" : "menu-item"}>
                                    <Link to="/transactions">
                                        <span class="menu-icon">
                                            <RiWallet2Fill />
                                        </span>
                                        <span class="menu-title">Manage Transaction</span>
                                    </Link>
                                </li>} */}

                                {subAdminAccess?.some(item => item.moduleName === "Manage User Support") && <li className={window.location.pathname.startsWith('/manage-usersupport') ? "menu-item active" : "menu-item"}>
                                    <Link to="/manage-usersupport">
                                        <span class="menu-icon">
                                            <RiCustomerService2Fill />
                                        </span>
                                        <span class="menu-title">Manage User Support</span>
                                    </Link>
                                </li>}
                                {
                                    subAdminAccess?.some(item => item.moduleName === "Manage Static") &&
                                    <li ref={staticMenu} class="menu-item sub-menu" onClick={handleStaticMenu}>
                                        <Link>
                                            <span class="menu-icon">
                                                <RiFileCopy2Fill />
                                            </span>
                                            <span class="menu-title">Manage Static</span>
                                        </Link>
                                        <div ref={staticSubMenu} class="sub-menu-list" onClick={(e) => e.stopPropagation()}>
                                            <ul>
                                                <li class="menu-item">
                                                    <Link to='/region-country'>
                                                        <span class="menu-title">Country & Region</span>
                                                    </Link>
                                                </li>
                                                <li class="menu-item">
                                                    <Link to="/faqs">
                                                        <span class="menu-title">FAQ's</span>
                                                    </Link>
                                                </li>
                                                <li class="menu-item">
                                                    <Link to="/about-us">
                                                        <span class="menu-title">About us</span>
                                                    </Link>
                                                </li>
                                                <li class="menu-item">
                                                    <Link to="/terms-condition">
                                                        <span class="menu-title">Terms & Conditions</span>
                                                    </Link>
                                                    <Link to="/privacy-policy">
                                                        <span class="menu-title">Privacy Policy</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                }
                                {
                                    subAdminAccess?.some(item => item.moduleName === "Model Withdrawals") && <li className={window.location.pathname.startsWith('/model-withdrawls') ? "menu-item active" : "menu-item"}>
                                        <Link to="/model-withdrawls">
                                            <span className="menu-icon">
                                                <RiWallet2Fill />
                                            </span>
                                            <span className="menu-title">Model Withdrawls</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    subAdminAccess?.some(item => item.moduleName === "Bank Details") && <li className={window.location.pathname.startsWith('/bank-details') ? "menu-item active" : "menu-item"}>
                                        <Link to="/bank-details">
                                            <span className="menu-icon">
                                                <RiBankFill />
                                            </span>
                                            <span className="menu-title">Bank Details</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    subAdminAccess?.some(item => item.moduleName === "Crypto Payout") && <li className={window.location.pathname.startsWith('/crypto-payout') ? "menu-item active" : "menu-item"}>
                                        <Link to="/crypto-payout">
                                            <span className="menu-icon">
                                                <RiBitCoinFill />
                                            </span>
                                            <span className="menu-title">Crytpo Payout</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    subAdminAccess?.some(item => item.moduleName === "Invitation Code") && <li className={window.location.pathname.startsWith('/invitation-code') ? "menu-item active" : "menu-item"}>
                                        <Link to="/invitation-code">
                                            <span className="menu-icon">
                                                <MdOutlineInsertInvitation />
                                            </span>
                                            <span className="menu-title">Invitation Code</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    subAdminAccess?.some(item => item.moduleName === "Sub-Admin Management") && <li className={window.location.pathname.startsWith('/sub-admin') ? "menu-item active" : "menu-item"}>
                                        <Link to="/sub-admin">
                                            <span class="menu-icon">
                                                <RiTeamFill />
                                            </span>
                                            <span class="menu-title">Sub-Admin Management</span>
                                        </Link>
                                    </li>
                                }
                                {
                                    subAdminAccess?.some(item => item.moduleName === "Report") && <li className={window.location.pathname.startsWith('/report') ? "menu-item active" : "menu-item"}>
                                    <Link to="/report">
                                        <span class="menu-icon">
                                            <TbReportSearch />
                                        </span>
                                        <span class="menu-title">Report</span>
                                    </Link>
                                </li>
                                }
                                <li class="menu-item" onClick={() => setShowLogoutModal(true)}>
                                    <a>
                                        <span class="menu-icon">
                                            <FiLogOut />
                                        </span>
                                        <span class="menu-title">Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </aside>
        </>
    );
}

export default Sidebar;