import { Link, useNavigate } from "react-router-dom";
import { deleteFaq, faqList } from "../../services/services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RiDeleteBin3Fill } from "react-icons/ri";
import { BsPlusLg } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

const FaqListing = () => {
    const navigate = useNavigate()
    const [faqs, setFaqs] = useState([])
    const listing = () => {
        faqList().then(res => {
            console.log('res--->',res)
            if (res.status == 200) {
                setFaqs(res.data.data)
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })
    }

    const handleDeletion = (id) => {
        console.log(id);
        deleteFaq(id).then(res => {
            if (res.status == 200) {
                toast.success(res.data.message)
                listing()

            }
        }).catch((err) => {
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })
    }

    useEffect(() => {
        listing()
    }, []);
    return (
        <>
            <main className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix mt-3 mb-4">
                                <div className="float-start">
                                    <h2 className="text-contact">FAQ's</h2>
                                </div>
                                {/* <div className="float-end">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb contact-bread">
                                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                                <li className="breadcrumb-item"><a href="#">Manage Static</a></li>
                                                <li className="breadcrumb-item active" aria-current="page">Faq</li>
                                            </ol>
                                        </nav>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <span className="see-more" onClick={() => navigate('/add-faq')} >Add New <BsPlusLg style={{ marginLeft: "5px" }} /></span>

                        <div className="table-responsive">

                            <table className="table table-first text-nowrap" id="table">
                                <thead>
                                    <tr>
                                        <th>Sr no.</th>
                                        <th>Question</th>
                                        <th>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {faqs && faqs.map((faq, i) =>
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{faq.question}</td>
                                            <td>

                                                <Link to={`/edit-faq/${faq._id}`} className="me-2">
                                                    <button className="btn btn-light" type="button"><FiEdit />
                                                    </button>
                                                </Link>
                                                <Link onClick={() => handleDeletion(faq._id)}>
                                                    <button className="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            {faqs.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default FaqListing;