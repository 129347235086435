import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { createInvitationCode } from "../../../services/services";

const buttonWidth = {
    width: "100%",
};

const AddInvitationCode = (props) => {
    const { show, handleClose, onDataPassCreateCode } = props;
    const navigate = useNavigate();
    const [invitationCode, setInvitationCode] = useState('');
    const [inputError, setInputError] = useState('');

    const handleChangeCode = (e) => {
        const value = e.target.value.toUpperCase();
        if (/^[A-Z0-9]*$/.test(value) && value.length <= 8) {
            setInvitationCode(value);
            setInputError('');
        } else {
            setInputError('Input must be 8 characters long and contain only uppercase letters and digits.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (invitationCode.length !== 8) {
            setInputError('Invitation Code must be exactly 8 characters long.');
            return;
        }
        handleClose();
        let data = { invitationCode };
        createInvitationCode(data).then((res) => {
            console.log(res);
            if (res.status === 201) {
                toast.success(res?.data?.message);
                onDataPassCreateCode(res?.data?.success);
                setInvitationCode('')
                setInputError('')
            }
        }).catch((err)=>{
            console.log(err)
            toast.error(err?.response?.data?.message);
        })
    };

    return (
        <Modal show={show} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">Create Invitation Code</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <label className="form-label">Invitation Code</label>
                        <input
                            type="text"
                            className="form-control py-3"
                            placeholder="Enter Invitation Code"
                            value={invitationCode}
                            onChange={handleChangeCode}
                            maxLength={8} // Ensure the input field does not accept more than 8 characters
                        />
                        {inputError && <div className="text-danger mt-2">{inputError}</div>}
                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-invite next fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default AddInvitationCode;
