import { createContext, useContext } from "react";

export const AdminContext = createContext({
    isLoggedIn:false,
    setIsLoggedIn:()=>{},
    token:null,
    setToken:()=>{},
    adminAccess:null,
    setAdminAccess:()=>{}
})

export const AdminContextProvider = AdminContext.Provider

export default function useAdminContext(){
    return useContext(AdminContext)
}
