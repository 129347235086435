import { Link, useNavigate } from 'react-router-dom';
import { forgetPassword } from '../services/services';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const ForgetPassword = () => {
    const navigate=useNavigate()
    const {register,handleSubmit,formState:{errors}}=useForm()
    const forgetPass=(data)=>{
        forgetPassword(data).then(res=>{
            if(res.status==200){
                toast.success("Please check your inbox for otp.")
                navigate('/otp',{state:data.email})
            }
        }).catch((err) => {
            if (err.response.status == 400) {
                toast.error(err.response.data.message)

            }
        })
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center mobile-reverse">
                    {/* <!-- First Column --> */}
                    <div className="col-xl-5 col-lg-5 ">
                        <div className="card border-0 login-form mt-lg-5 mt-2 mx-xl-5 mx-lg-3">
                            <div className="card-body p-xl-5">
                                <div className="logo mb-4">
                                    <a href="login.html">
                                        <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid" width="150"/>
                                    </a>
                                </div>
                                <h4 className="fw-bolder mb-2">Forgot Password?</h4>
                                <p className="mb-4 small">Enter your email and we'll send you an email to reset password.</p>

                                <form className="form-design my-auto" onSubmit={handleSubmit(forgetPass)}>
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fa fa-envelope"></i>
                                            </span>
                                            <input type="text" className="form-control" placeholder="Enter Email Address" {...register('email',{required:"Email is required.",pattern:{value:/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,message:"Invalid email"}})}/>
                                        </div>
                                        {errors.email && <span className='error'>{errors.email.message}</span>}
                                    </div>

                                    <button type="submit" className="btn btn-linear btn-block w-100">Submit</button>
                                </form>

                                <p className="text-center mt-3">Back to <Link to='/' className="fw-bolder forgot-password text-decoration-none">Login</Link></p>

                            </div>
                        </div>
                    </div>

                    {/* <!-- Shapes --> */}
                    <div className="shape"></div>
                    <div className="shape-2"></div>
                    {/* <!-- End Shapes --> */}


                    {/* <!-- Second Column --> */}

                    <div className="col-xl-7 px-0 col-lg-7">
                        <div className="bg-after h-100">
                            <img src={require('../assets/images/forgot.jpg')} alt="" className="img-fluid bg-login w-100"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgetPassword;
