import { useEffect, useState } from "react"
import { FaUsers } from "react-icons/fa"
import { retrieveUsers } from "../services/services"
import { toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { Dropdown, Tab, Tabs } from "react-bootstrap"
import { RiDeleteBin3Fill, RiEyeFill } from "react-icons/ri"
import { BsFillArrowRightSquareFill } from 'react-icons/bs'

const Dashboard = () => {
   const [users, setUsers] = useState([])
   const [userCount, setUserCount] = useState(0)
   let [modelCount, setModelCount] = useState(0)
   const [key, setKey] = useState('users');
   const navigate = useNavigate()
   const Users = () => {
      retrieveUsers().then(res => {
         if (res.status == 200) {
            setUserCount(res.data.data.filter((user) => user.role == "user").length)
            setModelCount(res.data.data.filter((user) => user.role == "model").length)
            setUsers(res.data.data)
         }
      }).catch((err) => {
         if (err.response.statusCode == 401) {
            toast.error(err.response.data.message)
            //  localStorage .clear()
            // navigate('/')
         }
         if (err.response.status == 400) {
            toast.error(err.response.data.message)
            // navigate('/')
         }
      })
   }
   const handleSeeMore = (event) => {
      navigate(`/${key}`)
   }
   useEffect(() => {
      Users()
   }, []);
   return (
      <>
         <div id="overlay" className="overlay"></div>
         <div className="layout">
            <nav className="navbar navbar-expand-lg bg-white sticky-top">
               <div className="container-fluid">
                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                     <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                     <div className="d-none d-lg-flex ms-auto">
                        {/* <div className="dropdown">
                           <a className="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img src="https://www.w3schools.com/howto/img_avatar.png" alt="img-picture" className="img-fluid rounded-circle" width="40" />
                              <span className="ms-2">Admin</span>
                           </a>
                           <ul className="dropdown-menu border-0">
                              <li><a className="dropdown-item" href="profile.html"><i className="fa fa-user me-2"></i>Profile</a></li>
                              <li><a className="dropdown-item" href="login.html"><i className="fa fa-sign-out me-2"></i>Logout</a></li>
                           </ul>
                        </div> */}
                        <Dropdown className="dd">
                           <Dropdown.Toggle  id="dropdown-basic">
                              Admin
                           </Dropdown.Toggle>

                           <Dropdown.Menu>
                              <Dropdown.Item href="/change-password">Change Password</Dropdown.Item>

                           </Dropdown.Menu>
                        </Dropdown>
                     </div>
                  </div>
               </div>
            </nav>
            <main className="content">
               <div>
                  <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                     <i className="ri-menu-line ri-xl"></i>
                  </a>
                  <div className="container-fluid allCards-new">
                     <div className="row my-3 mb-12">
                        <div className="col-md-12">
                           <h4 className="dashboard-line">Dashboard</h4>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6 col-lg-4  mains-dashboards">
                           <div className="card border-0 position-ab card-1">
                              <div className="card-header border-0 py-0">
                                 <div className="float-end">
                                    <FaUsers />
                                 </div>
                              </div>
                              <div className="card-body py-0" onClick={()=>navigate('/users')}>
                                 <h2>{userCount}</h2>
                                 <h6>Total Users</h6>
                                 <p className="mb-0">This is dummy text.</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-6 col-lg-4  mains-dashboards">
                           <div className="card border-0 position-ab card-1">
                              <div className="card-header border-0 py-0">
                                 <div className="float-end">
                                    <FaUsers />
                                 </div>
                              </div>
                              <div className="card-body py-0" onClick={()=>navigate('/models')}>
                                 <h2>{modelCount}</h2>
                                 <h6>Total Models</h6>
                                 <p className="mb-0">This is dummy text.</p>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-4 mains-dashboards">
                           <div className="card border-0 position-ab card-1">
                              <div className="card-header border-0 py-0">
                                 <div className="float-end">
                                    <i className="fa-sharp fa-solid fa-car"></i>
                                 </div>
                              </div>
                              <div className="card-body py-0">
                                 <h2>23</h2>
                                 <h6>Transactions</h6>
                                 <p className="mb-0">This is dummy text.</p>
                              </div>
                           </div>
                        </div>

                     </div>

                     <div className="row my-3 mb-12">
                        <div className="col-md-12">
                           <h4 className="dashboard-line">All Users</h4>
                        </div>
                     </div>

                     <div>
                        <span className="see-more" onClick={handleSeeMore}>see more <BsFillArrowRightSquareFill style={{ marginLeft: "5px" }} /></span>
                        <Tabs

                           id="uncontrolled-tab-example"
                           activeKey={key}
                           onSelect={(k) => setKey(k)}
                           className="mb-3"
                        >
                           <Tab eventKey="users" title="Users">
                              <div className="table-responsive">
                                 <table className="table table-first text-nowrap" id="table">
                                    <thead>
                                       <tr>
                                          <th>Sr no.</th>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Gender</th>
                                          <th>Region</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {users && users.filter((user) => user.role == "user").slice(0, 3).map((user, index) =>
                                          <tr>
                                             <td>{index + 1}</td>
                                             <td>
                                                {/* <img src={process.env.REACT_APP_IMAGE_URI + user.avatar} alt="image" width="40" height="40" className="rounded-circle me-3" /> */}
                                                {user.username.length>10?user.username.substring(0,12)+'...':user.username}
                                             </td>
                                             <td>{user.email}</td>
                                             <td>{user.gender}</td>
                                             <td>{user.country}</td>
                                             <td>
                                                <Link to={`/userDetails/${user._id}`} className="me-2">
                                                   <button className="btn btn-light" type="button"><RiEyeFill /></button>
                                                </Link>
                                                <a href="#modal-delete" data-bs-toggle="modal">
                                                   <button className="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                   </button>
                                                </a>
                                             </td>
                                          </tr>
                                       )}
                                    </tbody>
                                 </table>
                              </div>
                           </Tab>
                           <Tab eventKey="models" title="Models">
                              <div className="table-responsive">
                                 <table className="table table-first text-nowrap" id="table">
                                    <thead>
                                       <tr>
                                          <th>Sr no.</th>
                                          <th>Name</th>
                                          <th>Email</th>
                                          <th>Gender</th>
                                          <th>Region</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {users && users.filter((user) => user.role == "model").slice(0, 3).map((model, i) =>
                                          <tr>
                                             <td>{i + 1}</td>
                                             <td>
                                                {/* <img src={process.env.REACT_APP_IMAGE_URI + model.avatar} alt="image" width="40" height="40" className="rounded-circle me-3" /> */}
                                                {model.username.length>10?model.username.substring(0,12)+'...':model.username}
                                             </td>
                                             <td>{model.email}</td>
                                             <td>{model.gender}</td>
                                             <td>{model.region}</td>
                                             <td>
                                                <Link to={`/modelDetails/${model._id}`} className="me-2">
                                                   <button className="btn btn-light" type="button"><RiEyeFill /></button>
                                                </Link>
                                                <a href="#modal-delete" data-bs-toggle="modal">
                                                   <button className="btn btn-light" type="button"><RiDeleteBin3Fill />
                                                   </button>
                                                </a>
                                             </td>
                                          </tr>
                                       )}
                                    </tbody>
                                 </table>
                              </div>
                           </Tab>

                        </Tabs>
                     </div>
                  </div>
               </div>
            </main>
            <div className="overlay"></div>
         </div>
      </>
   );
}

export default Dashboard;