import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import {createSubAdmin} from "../../../services/services"
import LoadingAction from "../../Loader";



const buttonWidth = {
    width: "100%",
};

const AddSubAdmin = (props) => {
    const { show, handleClose,modulesList,addSubAdminPass } = props;
    const [subAdmin, setSubAdmin] = useState({
        username: "",
        email: "",
        password: "",
        modules: [],
    });
    const [showLoader, setShowLoader] = useState(false);
   
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setSubAdmin((prevState) => ({
                ...prevState,
                modules: checked
                    ? [...prevState.modules, value]
                    : prevState.modules.filter((module) => module !== value),
            }));
        } else {
            setSubAdmin((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // handleClose()
        console.log("Sub-admin data:", subAdmin.modules.length);
        subAdmin['role']='sub_admin'
        setShowLoader(true)
        createSubAdmin(subAdmin).then(res => {
            if (res.status == 201) {
                toast.success('Sub-Admin added successfully.')
                addSubAdminPass(res.status)
                setShowLoader(false)
                handleClose()
                setSubAdmin({
                    username: "",
                    email: "",
                    password: "",
                    modules: [],
                });             
            }
        }).catch((err) => {

            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                setShowLoader(false)
                // localStorage.clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                setShowLoader(false)
                toast.error(err.response.data.message)
                // localStorage.clear()
                // navigate('/')
            }
        })

        // Reset form
        
    };

    

    return (
        <Modal show={show} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">Add Sub Admin</Modal.Title>
                </div>
            </Modal.Header>
           {showLoader && <LoadingAction />} 
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n">

                    <div className="mb-4">
                        <label className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control py-3"
                            name="username"
                            value={subAdmin.username}
                            onChange={handleChange}
                            required
                        />

                    </div>
                    <div className="mb-4">
                        <label className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control py-3"
                            name="email"
                            value={subAdmin.email}
                            onChange={handleChange}
                            required
                        />

                    </div>
                    <div className="mb-4">
                        <label className="form-label">Password</label>
                        <input
                            type="password"
                            className="form-control py-3"
                            name="password"
                            value={subAdmin.password}
                            onChange={handleChange}
                            required
                        />

                    </div>
                    <div className="mb-4" >
                        <div>
                            <label className="form-label">Select Modules</label>
                        </div>
                        <div className="grid-module">

                            {modulesList.map((module) => (
                                <label key={module._id} className="mb-2" >
                                    <input
                                        type="checkbox"
                                        name="modules"
                                        className="me-2"
                                        value={module._id}
                                        checked={subAdmin.modules.includes(module._id)}
                                        onChange={handleChange}
                                    />
                                    {module?.moduleName}
                                </label>
                            ))}
                        </div>

                    </div>
                    <div className="text-center">
                        <button
                            type="submit"
                            className="btn btn-invite next fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};


export default AddSubAdmin