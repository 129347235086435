import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { retrieveUserProfile } from "../../services/services";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ShimmerText } from "react-shimmer-effects";

const UserDetails = () => {
   let params = useParams()
   const navigate = useNavigate()
   const [user, setUser] = useState({})
   const [loading, setLoading] = useState(true);
   const RetrieveProfile = () => {
      retrieveUserProfile(params.id).then(res => {
         if (res.status == 200) {
            setUser(res.data.data)
            setLoading(false);
         }
      }).catch((err) => {
         if (err.response.status == 401 || err.response.status == 400) {
            toast.error(err.response.data.message)
         }
      })
   }

   useEffect(() => {
      RetrieveProfile()
   }, []);
   return (
      <>
         <main className="content">
            <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
               <i className="ri-menu-line ri-xl"></i>
            </a>

            <div className="container-fluid">
               <div className="row mt-3 mb-4">
                  <div className="col-md-3">
                     <Link to={"/users"} className="text-dark d-flex fw-bolder"><i className="ri-arrow-left-line"></i>Back</Link>
                  </div>
               </div>
            </div>
            <div className="container-fluid">
               <div className="row">
                  <div className="col-12">
                     <div className="card border-0 manage-usr-text mb-5">

                        <div className="card-header bg-white border-0">
                           <h4 className="text-contact mt-2">View</h4>
                        </div>

                        <div className="card-body">
                           <div className="row align-items-center">
                              <div className="col-md-3">
                                 <figure>
                                    <img src={process.env.REACT_APP_IMAGE_URI + user.avatar} alt="image" width="200" height="200" className="rounded-circle mx-auto d-block" />
                                    <figcaption className="text-center mt-3 text-dark fw-bolder">Profile Picture</figcaption>
                                 </figure>

                              </div>
                              <div className="col-md-9">

                                 <div className="row simmer-common-text">

                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Name</p>
                                          {loading ? (
                                             <ShimmerText line={1} />
                                          ) : (
                                             <h5>{user.username}</h5>
                                          )}

                                       </div>
                                    </div>
                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Email</p>
                                          {loading ? (
                                             <ShimmerText line={1} />
                                          ) : (
                                             <h5>{user.email}</h5>
                                          )}
                                          
                                       </div>
                                    </div>

                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Country</p>
                                          {loading ? (
                                             <ShimmerText line={1} />
                                          ) : (
                                             <h5>{user.country?user.country:"NIL"}</h5>
                                          )}
                                         
                                       </div>
                                    </div>


                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Region</p>
                                          {loading ? (
                                             <ShimmerText line={1} />
                                          ) : (
                                             <h5>{user.region?user.region:"NIL"}</h5>
                                          )}
                                          
                                       </div>
                                    </div>

                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Gender</p>
                                          {loading ? (
                                             <ShimmerText line={1} />
                                          ) : (
                                             <h5>{user.gender?user.gender:"NIL"} </h5>
                                          )}
                                          
                                       </div>
                                    </div>

                                    <div className="col-md-4">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Age</p>
                                          {loading ? (
                                             <ShimmerText line={1} />
                                          ) : (
                                             <h5>{user.age} </h5>
                                          )}
                                         
                                       </div>
                                    </div>

                                    <div className="col-md-12">
                                       <div className="mb-4 border-bottom">
                                          <p className="mb-2">Bio</p>
                                          {loading ? (
                                             <ShimmerText line={1} />
                                          ) : (
                                             <h5>{user.bio}</h5>
                                          )}
                                        
                                       </div>
                                    </div>


                                    <div className="col-md-2">
                                       <button type="button" className="btn btn-linear btn-block w-100" onClick={() => navigate(-1)}>Close</button>
                                    </div>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </main>
      </>
   );
}

export default UserDetails;