import { useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar";
// import AppRoutes from "../routes/routes";

const Layout = ({children}) => {

    // console.log('hi')
    return (
        <>
         <div className="layout has-sidebar fixed-sidebar fixed-header">
            {(localStorage.getItem('token') !== null && localStorage.getItem('token') !== "undefined") &&  <Sidebar />}

            {children}
            </div>

        </>
    );
}

export default Layout;