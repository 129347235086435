import React, { useState, useEffect } from 'react';
import { getPrivacyPolicy } from '../../services/services';

const PrivacyAndPolicy = () => {
    const [data, setData] = useState('');

    const getContent = () => {
        getPrivacyPolicy().then(res => {
            if (res?.status === 200) {
                if (res?.data?.data?.length !== 0) {
                    setData(res?.data?.data?.content);
                }
            }
        });
    };

    useEffect(() => {
        getContent();
    }, []);

    const renderHTML = () => {
        // Use dangerouslySetInnerHTML to render HTML content
        return { __html: data };
    };

    return (
        <div className="m-4">
            <h2 className="text-contact mb-4">Privacy Policy</h2>
            <div
                className="static-content-web-url"
                dangerouslySetInnerHTML={renderHTML()}
            />
        </div>
    );
};

export default PrivacyAndPolicy;
