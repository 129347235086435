import React, { useEffect, useState } from 'react'
import { RiEditFill, RiEyeFill } from 'react-icons/ri'
import { GrView } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom'
import { getModules, getSubAdmin,deleteSubAdmin } from "../../services/services"
import { toast } from "react-toastify";
import AddSubAdmin from './addSubAdmin/AddSubAdmin';
import DeleteSubAdmin from "./deleteSubAdmin/DeleteSubAdmin"
import EditSubAdmin from './editSubAdmin/EditSubAdmin';
import ViewSubAdmin from './viewSubAdmin/ViewSubAdmin';
import LoadingAction from '../Loader';


const SubAdminManagement = () => {
    const [show, setShow] = useState(false)
    const [editShow, setEditShow] = useState(false)
    const [viewShow, setViewShow] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [invitationCodeList, setInvitationCodeList] = useState([])
    const [invitationCodeId, setInvitationCodeId] = useState('')
    const [modulesList, setModulesList] = useState([])
    const [subAdminList, setSubAdminList] = useState([])
    const [showLoader, setShowLoader] = useState(false);

    const getSubAdminList = () => {
        getSubAdmin().then((res) => {
            if (res.status == 200) {
                setSubAdminList(res?.data?.data)
            }
        })

    }
    const handleClose = () => {
        setShow(false)
        setEditShow(false)
        setShowDeleteModal(false)
        setViewShow(false)
    }
    const editHandler = (id) => {
        setInvitationCodeId(id)
        setEditShow(true)
    }

    const deleteHandler = (id) => {
        setInvitationCodeId(id)
        setShowDeleteModal(true)
    }
    const viewHandler = (id) => {
        setInvitationCodeId(id)
        setViewShow(true)
    }
    const onDataEditPass = (newData) => {
        if (newData) {
        }
    }
    const getModuleList = () => {
        getModules().then((res) => {
            if (res.status == 200) {
                setModulesList(res?.data?.data)
            }
        })

    }

    const handleDeleteSubAdmin = () => {
        setShowLoader(true)
        deleteSubAdmin(invitationCodeId).then(res => {
            if (res.status == 200) {
                setShowLoader(false)
                toast.success(res?.data?.message)
                getSubAdminList()
            }
        }).catch((err) => {
            if (err.response.status == 401) {
                setShowLoader(false)
                toast.error(err.response.data.message)
            }
            if (err.response.status == 400) {
                setShowLoader(false)
                toast.error(err.response.data.message)
            }
        })
    }


    const addSubAdminPass = (newData) => {
        if (newData == 201) {
            getSubAdminList()
        }

    }
    const editSubAdminPass = (newData)=>{
       if(newData){
        getSubAdminList()
       }
    }

    useEffect(() => {
        getModuleList()
    }, [])
    useEffect(() => {
        getSubAdminList()
    }, [])


    return (
        <>
            <main className="content">
                <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                    <i className="ri-menu-line ri-xl"></i>
                </a>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix mt-3 mb-4">
                                <div className="float-start">
                                    <h2 className="text-contact">Sub-Admin Management</h2>
                                </div>
                                <div className="float-end">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb contact-bread">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Sub-Admin</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                   {showLoader && <LoadingAction />} 
                    {show && (<AddSubAdmin show={show} handleClose={handleClose} modulesList={modulesList} addSubAdminPass={addSubAdminPass} />)}
                    {editShow && (<EditSubAdmin editShow={editShow} handleClose={handleClose} modulesList={modulesList} subAdminId={invitationCodeId}  editSubAdminPass={editSubAdminPass}/>)}
                   {viewShow && (<ViewSubAdmin viewShow={viewShow} handleClose={handleClose} subAdminId={invitationCodeId}/>)} 
                    {showDeleteModal && (<DeleteSubAdmin showDeleteModal={showDeleteModal} handleClose={handleClose} handleDelete={handleDeleteSubAdmin} />)}
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0 manage-usr-text mb-5">
                                <div className="card-body">
                                    <div className="search-user">
                                        {/* <label>Search:<input type="search" className="search-input" placeholder="" aria-controls="table"  /></label> */}
                                        <button className='btn btn-invite' onClick={() => setShow(true)}> + Add Sub Admin</button>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-first text-nowrap" id="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr no.</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Modules</th>
                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subAdminList && subAdminList.map((sub, i) =>
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{sub.username}</td>
                                                        <td>{sub.email}</td>  
                                                        <td>{sub.modules[0].moduleName}</td>
                                                        <td align="center">
                                                            <button className="btn btn-light me-2" type="button" onClick={() => editHandler(sub._id)}><RiEditFill /></button>
                                                            <button className="btn btn-light me-2" type="button" onClick={() => deleteHandler(sub._id)}><MdDelete /></button>
                                                            <button className="btn btn-light " type="button" onClick={() => viewHandler(sub._id)}><RiEyeFill /></button>
                                                        </td>
                                                    </tr>
                                                )}




                                            </tbody>
                                        </table>
                                        {subAdminList.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        </>
    );
}

export default SubAdminManagement