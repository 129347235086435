import React from 'react'
import NoData from "../../assets/images/No_data-rafiki.svg"

const ModelBankDetrails = (props) => {
        const { bankDetail } = props
       
        return (
                <div><form className="form-login n  n">
                {bankDetail? <div className="row">
                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label">Name</label>
                                                <p className="h5">{bankDetail?.firstName ? bankDetail?.firstName : "NIL"}</p>
                                        </div>
                                </div>



                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label">Last Name</label>
                                                <p className="h5">{bankDetail?.lastName ? bankDetail?.lastName : "NIL"}</p>

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label">Email</label>
                                                <p className="h5">{bankDetail?.email ? bankDetail?.email : "NIL"}</p>

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4">
                                                <label className="form-label">Phone</label>
                                                <p className="h5">{bankDetail?.phone ? bankDetail?.phone : "NIL"}</p>

                                        </div>
                                </div>

                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">City</label>
                                                <p className="h5">{bankDetail?.city ? bankDetail?.city : "NIL"}</p>

                                        </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-4" >
                                                <label className="form-label">AddressLine 1</label>
                                                <p className="h5">{bankDetail?.addressLine1 ? bankDetail?.addressLine1 : "NIL"}</p>

                                        </div>
                                </div>
                                <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">AddressLine 2</label>
                                        <p className="h5">{bankDetail?.addressLine2 ? bankDetail?.addressLine2 : "NIL"}</p>

                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Account Type</label>
                                        <p className="h5">{bankDetail?.accountType ? bankDetail?.accountType : "NIL"}</p>

                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Bank Country</label>
                                        <p className="h5">{bankDetail?.bankCountry ? bankDetail?.bankCountry : "NIL"}</p>

                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Currency</label>
                                        <p className="h5">{bankDetail?.currency ? bankDetail?.currency : "NIL"}</p>

                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Personal ID Type</label>
                                        <p className="h5">{bankDetail?.personalIdType ? bankDetail?.personalIdType : "NIL"}</p>

                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Personal ID</label>
                                        <p className="h5">{bankDetail?.personalId ? bankDetail?.personalId : "NIL"}</p>
                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Postal Code</label>
                                        <p className="h5">{bankDetail?.postalCode ? bankDetail?.postalCode : "NIL"}</p>
                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Date Of Birth</label>
                                        <p className="h5">{bankDetail?.date_of_birth ? bankDetail?.date_of_birth.split('T')[0] : "NIL"}</p>
                                </div>
                        </div>

                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Bank Location</label>
                                        <p className="h5">{bankDetail?.bankLocation ? bankDetail?.bankLocation : "NIL"}</p>
                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Account Name</label>
                                        <p className="h5">{bankDetail?.accountName ? bankDetail?.accountName : "NIL"}</p>
                                </div>
                        </div>

                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Account Number</label>
                                        <p className="h5">{bankDetail?.accountNumber ? bankDetail?.accountNumber : "NIL"}</p>
                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Bank Code</label>
                                        <p className="h5">{bankDetail?.bankCode ? bankDetail?.bankCode : "NIL"}</p>
                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Bank Name</label>
                                        <p className="h5">{bankDetail?.bankName ? bankDetail?.bankName : "NIL"}</p>
                                </div>
                        </div>
                        <div className="col-md-4">
                                <div className="mb-4" >
                                        <label className="form-label">Created Date</label>
                                        <p className="h5">{bankDetail?.createdAt ? bankDetail?.createdAt.split('T')[0] : "NIL"}</p>
                                </div>
                        </div>
                </div>: <img src={NoData} alt="" className="object-fit-cover d-block mx-auto" width={500} height={500} />}
                       

                </form ></div >
        )
}


export default ModelBankDetrails