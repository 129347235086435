import React from 'react'
import { GetUrl } from '../../config/GetUrl'
import WrittingPng from '../../assets/images/writings.png'
import NoData from "../../assets/images/No_data-rafiki.svg"

const ModelCryptoPayout = (props) => {
    const { cryptoPayout } = props
    return (
        <div><form className="form-login n  n">
            {
                cryptoPayout?  <div className="row align-items-center">
                <div className="col-md-4">
                    <div className="mb-4">
                        <label className="form-label">email</label>
                        <p className="h5">{cryptoPayout?.email ? cryptoPayout?.email : "NIL"}</p>

                    </div>
                </div>
                <div className="col-md-4">
                    <div className="mb-4">
                        <label className="form-label">userName</label>
                        <p className="h5">{cryptoPayout?.email ? cryptoPayout?.email : "NIL"}</p>

                    </div>
                </div>
                <div className="col-md-4"> <div className="mb-">
                    <label className="form-label">Created Date</label>
                    <p className="h5">{cryptoPayout?.createdAt ? cryptoPayout?.createdAt.split('T')[0] : "NIL"}</p>

                </div></div>

                <div className="col-md-5"> <div className="mb-4">
                    <label className="form-label">Document (Upload Qr Code)</label>
                   {cryptoPayout?.upload_Qr_code?<div class="card">
                        <div class="card-body">
                            <img src={WrittingPng} class="card-img-top" alt="images" style={{ objectFit: "contain", height: "60px" }} />
                        </div>

                        <a href={GetUrl.IMAGE_URL + cryptoPayout.upload_Qr_code} target="_blank" class="stretched-link"></a>
                    </div>:<div className="card">
                        <div className="card-body nil-box">

                        <p className="h5">NIL</p>

                        </div>
                    </div>} 

                </div></div>
                
               <div className='col-md-2'>
                   <h5 className='text-center '>OR</h5>
               </div>
               <div className="col-md-5"><div className="mb-4">
                    <label className="form-label">virstial_address_text</label>

                    <div className="card">
                        <div className="card-body nil-box">

                        <p className="h5">{cryptoPayout?.virstial_address_text ? cryptoPayout?.virstial_address_text : "NIL"}</p>

                        </div>
                    </div>
                   

                </div></div>
               
               

            </div>:<img src={NoData} alt="" className="object-fit-cover d-block mx-auto" width={500} height={500} />
            }
          



        </form></div>
    )
}




export default ModelCryptoPayout