import { FaCheck, FaTimes } from 'react-icons/fa';
import { restriction, retrieveModalTransactions } from '../../services/services'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ReactPaginate from 'react-paginate';
import { FaBan } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { RestrictPopUp,ApproveTransaction } from '../../utils/restrictionPopUp'

const ModelTransaction = () => {
    const navigate = useNavigate()
    const [users, setUsers] = useState([])
    const [paginated, setPaginated] = useState([])
    const [page, setPage] = useState(0)
    const [offset, setOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState('')


    const ModelTransaction = () => {
        retrieveModalTransactions().then(res => {
            if (res.status == 200) {

                setPageCount(Math.ceil(res.data.data.length / itemsPerPage))
                setUsers(res.data.data.filter((user) => user))
            }
        }).catch((err) => {
            console.log(err.response.status);
            if (err.response.status == 401) {
                toast.error(err.response.data.message)
                //  localStorage .clear()
                // navigate('/')
            }
            if (err.response.status == 400) {
                toast.error(err.response.data.message)
                //  localStorage .clear()
                // navigate('/')
            }
        })
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        setOffset(0)
        setPage(0)

    }

    const paginationHandler = () => {
        const endOffset = offset + itemsPerPage;
        let paginated = []
        if (search.length > 0) {
            let searchArr = users.filter((item) => item.reciever.username.toLowerCase().includes(search.toLowerCase()))
            setPageCount(Math.ceil(searchArr.length / itemsPerPage))
            paginated = searchArr.slice(offset, endOffset)
        }
        else {
            setPageCount(Math.ceil(users.length / itemsPerPage))
            paginated = users.slice(offset, endOffset)
        }
        setPaginated(paginated)
    }
    const handlePageClick = (e) => {
        setPage(e.selected)
        console.log("selected",e.selected);
        const newOffset = (e.selected * itemsPerPage);
        setOffset(newOffset)
    }

    const handleTransactionStatus=(status,user)=>{
        // console.log(status);
        ApproveTransaction(status,user,ModelTransaction)

    }

    useEffect(() => {
        ModelTransaction()
    }, []);

    useEffect(() => {
        paginationHandler();
        console.log('loading......')
    }, [users, offset, search]);
    useEffect(()=>{
        console.log('users : ',users)
    },[users])
    return (
        <>
            <main className="content">
                <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                    <i className="ri-menu-line ri-xl"></i>
                </a>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="clearfix mt-3 mb-4">
                                <div className="float-start">
                                    <h2 className="text-contact">Manage Model Transactions</h2>
                                </div>
                                <div className="float-end">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb contact-bread">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Model Transactions</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card border-0 manage-usr-text mb-5">
                                <div className="card-body">
                                    <div className="search-user"><label>Search:<input type="search" className="search-input" placeholder="" aria-controls="table" onChange={handleSearch} /></label></div>
                                    <div className="table-responsive">
                                        <table className="table table-first text-nowrap" id="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr no.</th>
                                                    <th>Name</th>
                                                    <th>Image</th>
                                                    <th>Email</th>
                                                    <th>Gender</th>
                                                    <th> Transaction Amount  </th>
                                                    <th> Status</th>
                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {paginated && paginated.map((user, index) =>
                                                    <tr key={index}>
                                                        <td>{((page + 1) * itemsPerPage - itemsPerPage) + index + 1}</td>
                                                        <td> {user?.reciever?.username?.length>10?user?.reciever?.username?.substring(0,15)+'...':user?.reciever?.username}</td>
                                                        <td>
                                                            <img src={process.env.REACT_APP_IMAGE_URI + user?.reciever?.avatar} alt="image" width="40" height="40" className="rounded-circle me-3" />

                                                        </td>
                                                        <td>{user.reciever?.email}</td>
                                                        <td>{user.reciever?.gender}</td>
                                                        <td>{user?.txn_amount}</td>
                                                        <td>{user?.status}</td>
                                                        <td align="center">
                                                            {user.status === "Pending" ? (
                                                                <div className="button-group">
                                                                <button className="btn btn-success btn-sm" type="button" onClick={() => handleTransactionStatus("Approved", user._id)}>
                                                                    <FaCheck /> Approve
                                                                </button>
                                                                <button className="btn btn-light btn-sm ms-2" type="button" onClick={() => handleTransactionStatus("Rejected", user._id)}>
                                                                    <FaTimes /> Reject
                                                                </button>
                                                                </div>
                                                            ) : (
                                                                <span className={user.status == 'Approved' ? 'text-success' :'text-danger' }>{user.status}</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        {paginated.length == 0 && <span className="no-data"><p>No Data Found</p></span>}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {users.length > 10 &&
                    <ReactPaginate
                        breakLabel="..."
                        breakClassName={'break'}
                        nextLabel="next >"
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel="< previous"

                    />
                }
            </main>

        </>
    );
}

export default ModelTransaction;