import { useForm } from 'react-hook-form';
import { changePassword, resetPassword } from '../services/services';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const loc = useLocation()
    const {register,handleSubmit,formState:{errors},watch}=useForm()
    const navigate = useNavigate()
    const submit=(data)=>{
        data['email']=loc.state
        delete data['confirmPass']
        resetPassword(data).then(res=>{
            if(res.status===200){
                toast.success(res.data.message)
                navigate('/')
            }
        })
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center mobile-reverse bg-svg">
                    {/* <!-- First Column --> */}
                    <div className="col-xl-5 col-lg-5 justify-content-center">
                        <div className="card border-0 login-form mt-lg-5 mt-2 mx-xl-5 mx-lg-3  bg-white">
                            <div className="card-body p-xl-5">
                                <div className="logo mb-2">
                                    <a href="login.html">
                                        <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid mx-auto d-block" width="150" />
                                    </a>
                                </div>
                                <h5 className="fw-bolder mb-4 text-center">Reset Password.</h5>
                                <p className="mb-2 small">Please reset your password.</p>

                                <form className="form-design my-auto" onSubmit={handleSubmit(submit)}>
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fa fa-lock"></i>
                                            </span>
                                            <input type="password" className="form-control" placeholder="Enter New Password" {...register('password',{required:"This field is required."})}/>
                                        </div>
                                        {errors.password && <span className='error'>{errors.password.message}</span>}
                                    </div>
                                    <div className="mb-4">
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                                <i className="fa fa-lock"></i>
                                            </span>
                                            <input type="password" className="form-control" placeholder="Enter Confirm Password" {...register('confirmPass',{required:"This field is required.",validate:v=>v===watch('password')||"Password did'nt match."})}/>
                                        </div>
                                        {errors.confirmPass && <span className='error'>{errors.confirmPass.message}</span>}
                                    </div>

                                   <button type="submit" className="btn btn-linear btn-block w-100">Reset Password</button>
                                </form>

                                <p className="text-center mt-3">Back to <a href="login.html" className="fw-bolder forgot-password text-decoration-none">Login</a></p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default ResetPassword;