
import './App.css';
import AppRoutes from './routes/routes';
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './layout/layout';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AdminContextProvider } from './context/appContext';
function App() {
  let loc= useLocation()
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') !== null && localStorage.getItem('token') !== "undefined");
  
  const [token, setToken] = useState(null)
  useEffect(() => {
  }, [loc]);
  let value = {
    isLoggedIn,
    setIsLoggedIn,
    token,
    setToken
  }
  return (
   

    <AdminContextProvider value={value}>
       <Layout>
     <AppRoutes/>
      </Layout>


      <ToastContainer theme='dark' />
    </AdminContextProvider>
    
 
  );
}

export default App;
