const TextEditor = () => {

    return (
        <>
            <div id="toolbar">
                <span className="ql-formats">
                    <select className="ql-font" defaultValue="arial">
                        {/* <option value="arial">Arial</option>
                        <option value="comic-sans">Comic Sans</option>
                        <option value="courier-new">Courier New</option>
                        <option value="georgia">Georgia</option>
                        <option value="helvetica">Helvetica</option>
                        <option value="lucida">Lucida</option> */}
                    </select>
                    <select className="ql-size" defaultValue="medium">
                        <option value="small">Size 1</option>
                        {/* <option value="false">Size 2</option> */}
                        <option value="large">Size 2</option>
                        <option value="huge">Size 3</option>
                    </select>
                    <select className="ql-header" defaultValue="3">
                        <option value="1">Heading</option>
                        <option value="2">Subheading</option>
                        <option value="3">Normal</option>
                    </select>
                </span>
                <span className="ql-formats">
                    <button className="ql-bold" />
                    <button className="ql-italic" />
                    <button className="ql-underline" />
                    <button className="ql-strike" />
                </span>
                <span className="ql-formats">
                    <button className="ql-list" value="ordered" />
                    <button className="ql-list" value="bullet" />
                    <button className="ql-link" />
                    <button className="ql-image" />
                    <button className="ql-code-block" />
                </span>
            </div>
        </>
    )
}

export default TextEditor;