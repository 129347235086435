import Swal from "sweetalert2"
import { restriction, unrestriction ,ApproveTrans,RejectTrans} from "../services/services"
import { toast } from "react-toastify"


export const RestrictPopUp = (status, user, Users) => {

    Swal.fire({
        title: `Do you want to ${status ? "unrestrict" : "restrict"} the user?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: status ? "Unrestrict" : 'Restrict',
        cancelButtonText: "Cancel",
        confirmButtonColor: "#FF4D67",
        // cancelButtonColor:"#e7e7e7"
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            const data = {
                userId: user
            }
            {
                status ?
                    unrestriction(data).then(res => {
                        if (res.status == 200) {
                            Swal.fire('', "Unrestricted successfully.", 'success')
                            Users()
                        }
                    }).catch(err => {
                        if (err.response.status == 401) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                        if (err.response.status == 400) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                    })
                    :
                    restriction(data).then(res => {
                        if (res.status == 200) {
                            Swal.fire('', "Restricted successfully.", 'success')
                            Users()
                        }
                    }).catch(err => {
                        if (err.response.status == 401) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                        if (err.response.status == 400) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                    })
            }
        } else if (result.isDenied) {
            Swal.close()
        }
    })
}

export const ApproveTransaction = (status, user, Users) => {

    Swal.fire({
        title: `Do you want to ${status == "Approved" ? "Approve" : "Reject" } the transaction?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: status == "Approved" ? "Approve" : "Reject",
        cancelButtonText: "Cancel",
        confirmButtonColor: "#FF4D67",
        // cancelButtonColor:"#e7e7e7"
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            
            const data = {
                userId: user
            }
            {
                status == "Approved" ?
                ApproveTrans(data).then(res => {
                        if (res.status == 200) {
                            console.log('ress--->',res)
                            Swal.fire('', "Transaction Approved successfully.", 'success')
                            Users()
                        }
                    }).catch(err => {
                        if (err.response.status == 401) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                        if (err.response.status == 400) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                    })
                    :
                    RejectTrans(data).then(res => {
                        if (res.status == 200) {
                            Swal.fire('', "Transaction Rejected successfully.", 'success')
                            Users()
                        }
                    }).catch(err => {
                        if (err.response.status == 401) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                        if (err.response.status == 400) {
                            toast.error(err.response.data.message)
                             localStorage .clear()

                        }
                    })
            }
        } else if (result.isDenied) {
            Swal.close()
        }
    })
}

