import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { verifyOtp } from '../services/services';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

const OtpVerification = () => {
    const loc = useLocation()
    const navigate=useNavigate()
    const [otp, setOtp] = useState('');
    const onSubmit = ()=>{
        if(otp==''){
            toast.error("please enter otp.")
        }
        else{
            const data={
                email:loc.state,
                otp:otp
            }
            verifyOtp(data).then(res=>{
                if(res.status==200){
                    toast.success('Otp verified successfully.')
                    navigate('/reset-password',{state:loc.state})
                }
            }).catch(err=>{
                if (err.response.status == 400) {
                    toast.error(err.response.data.message)

                }
            })
        }
    }
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center mobile-reverse bg-svg">
                    {/* <!-- First Column --> */}
                    <div className="col-xl-5 col-lg-5 justify-content-center">
                        <div className="card border-0 login-form mt-lg-5 mt-2 mx-xl-5 mx-lg-3  bg-white">
                            <div className="card-body p-xl-5">
                                <div className="logo mb-2">
                                    <a href="login.html">
                                        <img src={require('../assets/images/logo.png')} alt="logo" className="img-fluid mx-auto d-block" width="150" />
                                    </a>
                                </div>
                                <h5 className="fw-bolder mb-4 text-center">Please enter your otp.</h5>
                                {/* <p className="mb-2 small">Please reset your password.</p> */}
                                <OtpInput
                                    containerStyle="otp-container"
                                    inputStyle="otp-input"
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                                <div style={{marginTop:"25px"}} onClick={onSubmit}>
                                    <button type="submit" className="btn btn-linear btn-block w-100">verify</button>
                                </div>
                                {/* <p className="text-center mt-3">Back to <a href="login.html" className="fw-bolder forgot-password text-decoration-none">Login</a></p> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OtpVerification;